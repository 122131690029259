<!-- Kids.vue -->
<template>
    <div>
      <h2>Kids Site</h2>
      <!-- Add kids site content here -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'KidsSite',
  };
  </script>