<!-- Adult.vue -->
<template>
    <div>
      <h2>Adult Site</h2>
      <!-- Add adult site content here -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'AdultSite',
  };
  </script>